<template>
  <uds-simple-dropdown>
    <template #inputContent>
      <div
        class="filters-and-flags-chip"
        :class="{ 'is-active': isActive }"
        @click="displayFilterAndFlagDropdown = !displayFilterAndFlagDropdown"
      >
        <div class="chip-swap">
          <uds-icon
            class="icon"
            :class="{ 'is-active': isActive }"
            icon-name="swap_vert"
            size="small"
          />
        </div>
        <div v-if="workableWidth > 932" class="chip-text" :class="{ 'is-active': isActive }">
          Trier
        </div>
        <div v-if="isActive" class="chip-close" @click.stop="close">
          <uds-icon
            class="icon"
            icon-name="cancel"
            size="small"
            :class="{ 'is-active': isActive }"
          />
        </div>
      </div>
    </template>
    <template #dropdownContent>
      <component :is="pathDropdown" />
    </template>
  </uds-simple-dropdown>
</template>

<script setup lang="ts">
type LinkBuilders = {
  builder: () => string;
  dropdownName: string;
};

const props = defineProps<{
  workableWidth: number;
  state: string;
}>();

const displayFilterAndFlagDropdown = ref(false);

const pathDropdown = computed(() => {
  const path = config.value.dropdownName;
  // @ts-expect-error
  return defineAsyncComponent(() => import(`../../dropdowns/filters/${path}.vue`));
});

const storeComposable = useStoreComposable();
const activeStore = computed(() => storeComposable.activeStore(props.state));
const societyStore = useSocietyStore();

const statePathProperties = computed(() => activeStore.value?.queryProperties);

const isActive = computed(() => {
  switch (props.state) {
    case "products":
      // @ts-expect-error
      return statePathProperties.value.type !== null;
    case "banks":
      return !(
        (statePathProperties.value as any).orderBy === "dateValue" &&
        // @ts-expect-error
        statePathProperties.value.descending &&
        // @ts-expect-error
        !statePathProperties.value.withoutReceipt &&
        // @ts-expect-error
        !statePathProperties.value.onlyCredit &&
        // @ts-expect-error
        !statePathProperties.value.onlyDebit
      );
    case "sales":
      return (
        // @ts-expect-error
        !statePathProperties.value.descending ||
        // @ts-expect-error
        statePathProperties.value.status !== null ||
        // @ts-expect-error
        isDefined(statePathProperties.value.orderBy)
      );
    case "recovery":
      return (
        // @ts-expect-error
        !statePathProperties.value.descending ||
        // @ts-expect-error
        statePathProperties.value.status !== null ||
        // @ts-expect-error
        isDefined(statePathProperties.value.orderBy)
      );
    case "ztickets":
      // @ts-expect-error
      return !statePathProperties.value.descending;
    case "preCompta":
      return (
        // @ts-expect-error
        !statePathProperties.value.descending ||
        // @ts-expect-error
        statePathProperties.value.orderBy !== "date" ||
        // @ts-expect-error
        statePathProperties.value.minimumAmount ||
        // @ts-expect-error
        statePathProperties.value.maximumAmount ||
        // @ts-expect-error
        statePathProperties.value.type
      );
    default:
      return false;
  }
});

const config = computed(() => {
  switch (props.state) {
    case "banks":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultBankQueryProperties.value,
            // @ts-expect-error
            {
              ...statePathProperties.value,
              orderBy: "dateValue",
              descending: true,
              page: 1,
              withoutReceipt: false,
              transactionId: null,
              checkedIds: [],
              onlyCredit: false,
              onlyDebit: false,
            },
            "/banks",
          ),
        dropdownName: "BankFiltersAndFlagsDropdown",
      };
    case "personal":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultBankQueryProperties.value,
            // @ts-expect-error
            {
              ...statePathProperties.value,
              orderBy: "dateValue",
              descending: true,
              page: 1,
              withoutReceipt: false,
              transactionId: null,
              checkedIds: [],
              onlyCredit: false,
              onlyDebit: false,
            },
            "/purchases/expense-notes",
          ),
        dropdownName: "BankFiltersAndFlagsDropdown",
      };
    case "products":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultProductsQueryProperties.value,
            // @ts-expect-error
            { ...statePathProperties.value, type: null, page: 1, productId: null },
            "/sales/products",
          ),
        dropdownName: "ProductFiltersAndFlagsDropdown",
      };
    case "sales":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultSalesQueryProperties.value,
            // @ts-expect-error
            {
              ...statePathProperties.value,
              status: null,
              page: 1,
              itemId: null,
              checkedIds: [],
              descending: true,
              orderBy: null,
            },
            "/sales",
          ),
        dropdownName: "SalesFiltersAndFlagsDropdown",
      };
    case "recovery":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultRecoveryQueryProperties.value,
            // @ts-expect-error
            {
              ...statePathProperties.value,
              status: null,
              page: 1,
              itemId: null,
              descending: true,
              orderBy: null,
            },
            "/sales/recovery",
          ),
        dropdownName: "RecoveryFiltersAndFlagsDropdown",
      };
    case "ztickets":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultZTicketsQueryProperties.value,
            // @ts-expect-error
            { ...statePathProperties.value, descending: true, page: 1, zTicketId: null },
            "/sales/ztickets",
          ),
        dropdownName: "ZTicketFiltersAndFlagsDropdown",
      };
    case "generalLedger":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultGeneralLedgerQueryProperties.value,
            // @ts-expect-error
            { ...statePathProperties.value, descending: true, page: 1 },
            "/balancesheet/generalLedger",
          ),
        dropdownName: "GeneralLedgerFiltersAndFlagsDropdown",
      };
    case "balance":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultBalanceQueryProperties.value,
            // @ts-expect-error
            { ...statePathProperties.value, descending: true, page: 1 },
            "/balancesheet/balance",
          ),
        dropdownName: "BalanceFiltersAndFlagsDropdown",
      };
    case "category":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultCategoryQueryProperties.value,
            // @ts-expect-error
            { ...statePathProperties.value, descending: true, page: 1 },
            "/settings/chart-accounts",
          ),
        dropdownName: "CategoryFiltersAndFlagsDropdown",
      };
    case "purchases":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultPurchasesQueryProperties.value,
            // @ts-expect-error
            {
              ...statePathProperties.value,
              descending: true,
              page: 1,
              itemId: null,
              checkedIds: [],
            },
            "/purchases",
          ),
        dropdownName: "PurchasesFiltersAndFlagsDropdown",
      };
    case "payments":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultPaymentQueryProperties.value,
            // @ts-expect-error
            { ...statePathProperties.value, descending: true, page: 1 },
            "/sales/payments",
          ),
        dropdownName: "PaymentFiltersAndFlagsDropdown",
      };
    case "preCompta":
      return {
        builder: () =>
          useBuildRouteFromQueryPropertiesComposable(
            defaultPreComptaQueryProperties.value,
            // @ts-expect-error
            {
              ...statePathProperties.value,
              descending: true,
              page: 1,
              minimumAmount: null,
              maximumAmount: null,
              orderBy: "date",
              type: null,
            },
            `/admin/societies/${societyStore.society?.id}/config/precompta`,
          ),
        dropdownName: "PreComptaFiltersAndFlagsDropdown",
      };
    default:
      return {
        builder: null,
        dropdownName: null,
      };
  }
}) as ComputedRef<LinkBuilders>;

function close() {
  const link = config.value.builder();
  return navigateTo(link);
}
</script>

<style lang="scss">
.filters-and-flags-chip {
  background: $uds-neutral-200;
  border-radius: 17.5px;
  display: flex;
  align-items: center;
  height: 35px;
  min-width: 35px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &.is-active {
    background-color: $uds-google-blue;
  }

  .icon {
    cursor: pointer;
    color: $uds-neutral-800;
    &.is-active {
      color: $uds-white;
    }
  }

  .chip-swap {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }

  .chip-text {
    margin-right: 17px;
    white-space: nowrap;
    color: $uds-neutral-800;

    &.is-active {
      font-weight: $uds-weight-500;
      margin-right: 7px;
      color: $uds-white;
    }
  }

  .chip-close {
    display: flex;
    align-items: center;
    margin-right: 6px;
  }
}

.filters-and-flags-dropdown {
  background: $uds-white;
  display: flex;
  flex-direction: column;

  .filter-item {
    display: flex;
    align-items: center;
    padding: $uds-spacing-1_5;
    color: $uds-neutral-800;
    cursor: pointer;

    .uds-input-label {
      color: $uds-neutral-800;
    }

    &:hover {
      background-color: $uds-neutral-50;
    }

    .wrapper-item {
      display: flex;
      align-items: center;
      min-width: 218px;
    }

    .filter-checkbox {
      display: flex;
      align-items: center;
    }

    .filter-name {
      color: $uds-neutral-800;
    }
  }
}
</style>
