<template>
  <uds-simple-dropdown>
    <template #inputContent>
      <div class="uds-filter-tag">
        <div class="chip-text">{{ limit }}</div>
        <div class="chip-ico">
          <uds-icon class="icon" icon-name="arrow_drop_down" size="xsmall" />
        </div>
      </div>
    </template>
    <template #dropdownContent>
      <div class="npp-dropdown-list">
        <div>
          <span class="number-value" @click.stop="setLimit(25)">25</span>
        </div>
        <div class="number-item">
          <span class="number-value" @click.stop="setLimit(50)">50</span>
        </div>
        <div class="number-item">
          <span class="number-value" @click.stop="setLimit(100)">100</span>
        </div>
      </div>
    </template>
  </uds-simple-dropdown>
</template>

<script setup lang="ts">
const props = defineProps<{
  state: string;
}>();

const storeComposable = useStoreComposable();
const activeStore = computed(() => storeComposable.activeStore(props.state));
const societyStore = useSocietyStore();

const statePathProperties = computed(() => activeStore.value?.queryProperties);

const limit = computed(() => {
  return statePathProperties.value?.limit ?? 24;
});

interface LinkBuilders {
  [key: string]: () => string;
}

const route = useRoute();

function buildLinkFromQueryProperties(newValue: number) {
  let link = "";
  const defaultProperties = {
    ...statePathProperties.value,
    limit: newValue,
    page: 1,
  };

  const linkBuilders: LinkBuilders = {
    banks: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBankQueryProperties.value,
        // @ts-expect-error
        {
          ...defaultProperties,
          transactionId: null,
          checkedIds: [],
        },
        "/banks",
      ),
    personal: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBankQueryProperties.value,
        // @ts-expect-error
        {
          ...defaultProperties,
          checkedIds: [],
        },
        "/purchases/expense-notes",
      ),
    sales: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultSalesQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null, checkedIds: [] },
        "/sales",
      ),
    recovery: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultRecoveryQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null },
        "/sales/recovery",
      ),
    products: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultProductsQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, productId: null },
        "/sales/products",
      ),
    customers: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultThirdPartiesQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, thirdPartyId: null },
        "/sales/customers",
      ),
    providers: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultThirdPartiesQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, thirdPartyId: null },
        "/purchases/providers",
      ),
    ztickets: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultZTicketsQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, zTicketId: null },
        "/sales/ztickets",
      ),
    library: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultLibraryQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, documentId: null },
        "/library",
      ),
    auxiliaryLedger: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultAuxiliaryLedgerQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        route.name === "purchases-auxiliaryLedger"
          ? "/purchases/auxiliaryLedger"
          : "/sales/auxiliaryLedger",
      ),
    generalLedger: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultGeneralLedgerQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        "/balancesheet/generalLedger",
      ),
    balance: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBalanceQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        "/balancesheet/balance",
      ),
    employees: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultEmployeesQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        "/employees",
      ),
    vat: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultVatQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null },
        "/vat",
      ),
    adminVat: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultVatQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null },
        `/admin/societies/${societyStore.society?.id}/declaration/vat`,
      ),
    das2: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultVatQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null },
        "/vat/das2",
      ),
    adminDas2: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultVatQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null },
        `/admin/societies/${societyStore.society?.id}/declaration/das2`,
      ),
    payments: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPaymentQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        "/sales/payments",
      ),
    category: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultCategoryQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null },
        "/settings/chart-accounts",
      ),
    purchases: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPurchasesQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null, checkedIds: [] },
        "/purchases",
      ),
    balanceSheet: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBalanceSheetQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null },
        `/admin/societies/${societyStore.society?.id}/declaration/balancesheet`,
      ),
    companyTaxAdvances: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultCompanyTaxAdvanceQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null },
        `/admin/societies/${societyStore.society?.id}/declaration/companyTaxAdvances`,
      ),
    preCompta: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPreComptaQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/config/precompta`,
      ),
    adminSocieties: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultAdminSocietiesQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        "/admin/societies",
      ),
    specificAccountingPlan: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultSpecificAccountingPlanQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/config/accountplan/specific`,
      ),
    persoAccountingPlan: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPersoAccountingPlanQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/config/accountplan/perso`,
      ),
    genericAccount: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultGenericAccountQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        "/admin/genericaccount",
      ),
    adminRevision: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultRevisionQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/accounting/revisionaccount`,
      ),
    adminRevisionCustomer: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultRevisionTPQueryProperties,
        // @ts-expect-error
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/accounting/customers`,
      ),
    adminRevisionProvider: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultRevisionTPQueryProperties,
        // @ts-expect-error
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/accounting/providers`,
      ),
    expertAccountant: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultExpertAccountantQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties },
        "/expert/performances",
      ),
  };

  const linkBuilder = linkBuilders[props.state];

  if (linkBuilder) {
    link = linkBuilder();
  }

  return navigateTo(link);
}

function setLimit(newValue: number) {
  buildLinkFromQueryProperties(newValue);
}
</script>

<style lang="scss" scoped>
.icon {
  cursor: pointer;
  color: $uds-neutral-800;
}

.chip-text {
  font-size: 14px;
  line-height: 18px;
  margin-right: $uds-spacing-0_5;
  white-space: nowrap;
  font-size: 16px;
}

.chip-ico {
  display: flex;
  align-items: center;
}

.npp-dropdown-list {
  border-radius: $uds-radius-1;
  width: 100%;
  // on every direct child
  & > * {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $uds-spacing-0_5 0;
    box-sizing: border-box;
    color: $uds-neutral-900;

    &:hover {
      background-color: $uds-neutral-100;
      cursor: pointer;
    }
  }
}
</style>
