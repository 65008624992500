<template>
  <div class="search-chip-container" :class="{ 'is-focus': isFocus }">
    <div class="chip-icon">
      <uds-icon class="icon" icon-name="search" size="small" />
    </div>
    <div class="chip-center">
      <input
        ref="searchInput"
        :value="search"
        class="search-input"
        :class="{ 'is-reduced': workableWidth < 933 }"
        type="text"
        placeholder="Rechercher"
        @change="updateSearch($event)"
        @focus="focusEvent"
        @blur="focusEvent"
      >
    </div>
    <template v-if="search">
      <div class="chip-close" @click.stop="resetSearch">
        <uds-icon class="icon" icon-name="cancel" size="small" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  workableWidth: number;
  state: string;
}>();

const isFocus: Ref<boolean> = ref(false);

const storeComposable = useStoreComposable();
const activeStore = computed(() => storeComposable.activeStore(props.state));
const societyStore = useSocietyStore();

const statePathProperties = computed(() => activeStore.value?.queryProperties);

const search = computed(() => {
  // @ts-expect-error
  return statePathProperties.value?.search ?? "";
});

function focusEvent() {
  isFocus.value = document.querySelector(".search-input") === document.activeElement;
}

interface LinkBuilders {
  [key: string]: () => string;
}

const route = useRoute();

function buildLinkFromQueryProperties(newValue: string | null) {
  let link = "";
  const defaultProperties = {
    ...statePathProperties.value,
    search: newValue,
    page: 1,
  };

  const linkBuilders: LinkBuilders = {
    banks: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBankQueryProperties.value,
        {
          ...defaultProperties,
          // @ts-expect-error
          transactionId: null,
          checkedIds: [],
        },
        "/banks",
      ),
    personal: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBankQueryProperties.value,
        {
          ...defaultProperties,
          // @ts-expect-error
          transactionId: null,
          checkedIds: [],
        },
        "/purchases/expense-notes",
      ),
    sales: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultSalesQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null, checkedIds: [] },
        "/sales",
      ),
    recovery: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultRecoveryQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null },
        "/sales/recovery",
      ),
    products: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultProductsQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, productId: null },
        "/sales/products",
      ),
    customers: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultThirdPartiesQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, thirdPartyId: null },
        "/sales/customers",
      ),
    providers: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultThirdPartiesQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, thirdPartyId: null },
        "/purchases/providers",
      ),
    ztickets: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultZTicketsQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, zTicketId: null },
        "/sales/ztickets",
      ),
    library: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultLibraryQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, documentId: null },
        "/library",
      ),
    auxiliaryLedger: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultAuxiliaryLedgerQueryProperties.value,
        defaultProperties,
        route.name === "purchases-auxiliaryLedger"
          ? "/purchases/auxiliaryLedger"
          : "/sales/auxiliaryLedger",
      ),
    generalLedger: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultGeneralLedgerQueryProperties.value,
        { ...defaultProperties },
        "/balancesheet/generalLedger",
      ),
    balance: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBalanceQueryProperties.value,
        { ...defaultProperties },
        "/balancesheet/balance",
      ),
    employees: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultEmployeesQueryProperties.value,
        { ...defaultProperties },
        "/employees",
      ),
    payments: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPaymentQueryProperties.value,
        { ...defaultProperties },
        "/sales/payments",
      ),
    category: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultCategoryQueryProperties.value,
        { ...defaultProperties },
        "/settings/chart-accounts",
      ),
    adminSocieties: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultAdminSocietiesQueryProperties.value,
        { ...defaultProperties },
        "/admin/societies",
      ),
    purchases: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPurchasesQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null, checkedIds: [] },
        "/purchases",
      ),
    preCompta: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPreComptaQueryProperties.value,
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/config/precompta`,
      ),
    balanceSheetDocuments: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBalanceSheetDocumentsQueryProperties.value,
        // @ts-expect-error
        { ...defaultProperties, itemId: null },
        `/admin/societies/${societyStore.society?.id}/accounting/documents`,
      ),
    specificAccountingPlan: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultSpecificAccountingPlanQueryProperties.value,
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/config/accountplan/specific`,
      ),
    persoAccountingPlan: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPersoAccountingPlanQueryProperties.value,
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/config/accountplan/perso`,
      ),
    adminSocietyUsers: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultSocietyUsersQueryProperties.value,
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/config/users`,
      ),
  };

  const linkBuilder = linkBuilders[props.state];

  if (linkBuilder) {
    link = linkBuilder();
  }

  return navigateTo(link);
}

function updateSearch(event: Event) {
  const newValue = (event.target as HTMLInputElement)?.value ?? null;
  buildLinkFromQueryProperties(newValue);
}

function resetSearch() {
  buildLinkFromQueryProperties(null);

  const searchInput = ref<HTMLInputElement | null>(null);
  if (!isDefined(searchInput)) {
    return;
  }
  searchInput.value?.focus();
}
</script>

<style lang="scss" scoped>
.search-chip-container {
  background: $uds-neutral-200;
  border-radius: 17.5px;
  height: 35px;
  display: flex;
  align-items: center;

  &.is-focus {
    border: 1px solid $uds-primary-500;
  }

  .icon {
    color: $uds-neutral-800;
    cursor: pointer;
  }
  .chip-icon {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  .chip-center {
    margin: 0px $uds-spacing-1_5;

    .search-input {
      outline: none;
      font-weight: $uds-weight-450;
      color: $uds-neutral-800;
      &::placeholder {
        color: $uds-neutral-800;
        opacity: 1; // Firefox
      }
      &.is-reduced {
        width: 83px;
      }
    }
  }
  .chip-close {
    display: flex;
    align-items: center;
    margin-right: 6px;
  }

  input {
    background-color: transparent;
    border-style: none;
    color: inherit;
    font: inherit;
  }
}
</style>
