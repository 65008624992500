<template>
  <div>
    <CommonsTablesMainTopToolbarCalendarChipContentChip
      v-if="isMaxRangeOfDate"
      :workable-width="workableWidth"
      :start-date="startDate"
      :end-date="endDate"
      :is-modified="isModified"
      @click="resetDateStartAndDateEnd"
    />
    <uds-simple-dropdown v-else>
      <template #inputContent>
        <CommonsTablesMainTopToolbarCalendarChipContentChip
          :workable-width="workableWidth"
          :start-date="startDate"
          :end-date="endDate"
          :is-modified="isModified"
          @reset="resetDateStartAndDateEnd"
        />
      </template>
      <template #dropdownContent>
        <div class="date-range-container">
          <div class="fiscal-period-select-wrapper">
            <CommonsSelectsFiscalePeriodFiscalPeriodSelect
              :start-date="startDate"
              :end-date="endDate"
              @change-period="changeDateRange($event)"
            />
            <uds-action-button
              v-if="canCreateExercice"
              icon-name="edit"
              color="primary"
              size="xsmall"
              style="height:"
              @click="openModalManageFiscalPeriod()"
            />
          </div>
          <div class="date-picker-wrapper">
            <VDatePicker v-model.range="dateRange" locale="fr" borderless color="primary" />
          </div>
        </div>
      </template>
    </uds-simple-dropdown>
  </div>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import CreateExerciceModal from "../../modals/CreateExerciceModal.vue";

const dayjs = useDayjs();
const { formatDate } = useDateFormat();
const exerciceStore = useExerciceStore();
const societyStore = useSocietyStore();

const props = defineProps<{
  workableWidth: number;
  state: string;
}>();

const featureComposable = useFeatureComposable();
const storeComposable = useStoreComposable();
const activeStore = computed<any>(() => storeComposable.activeStore(props.state));

const statePathProperties = computed(() => {
  return activeStore.value?.queryProperties;
});

const startDate = computed(() => {
  return statePathProperties.value?.startDate ?? "1970-01-01";
});

const endDate = computed(() => {
  return statePathProperties.value?.endDate ?? "2170-12-31";
});

const dateRange = computed({
  get() {
    return { start: new Date(startDate.value), end: new Date(endDate.value) };
  },
  set(range: any) {
    buildLinkFromQueryProperties(
      formatDate(range.start, "YYYY-MM-DD"),
      formatDate(range.end, "YYYY-MM-DD"),
    );
  },
});

const isMaxRangeOfDate = computed<boolean>(() => {
  return endDate.value === "2170-12-31" && startDate.value === "1970-01-01";
});

const isModified = computed<boolean>(() => {
  const defaultStartDate = exerciceStore.defaultExercice.startDate;
  const defaultEndDate = exerciceStore.defaultExercice.endDate;
  if (
    isDefined(defaultStartDate) &&
    isDefined(defaultEndDate) &&
    isDefined(startDate.value) &&
    isDefined(endDate.value)
  ) {
    return (
      formatDate(defaultStartDate, "YYYY-MM-DD") !== formatDate(startDate.value, "YYYY-MM-DD") ||
      formatDate(defaultEndDate, "YYYY-MM-DD") !== formatDate(endDate.value, "YYYY-MM-DD")
    );
  }
  return false;
});

interface LinkBuilders {
  [key: string]: () => string;
}

const canCreateExercice = computed(() => featureComposable.canManageExercice());
const route = useRoute();

function buildLinkFromQueryProperties(startDate: string | null, endDate: string | null) {
  let link = "";
  const defaultProperties = {
    ...statePathProperties.value,
    startDate,
    endDate,
    page: 1,
  };

  const idSociety = useSocietyStore().society?.id ?? 0;

  const linkBuilders: LinkBuilders = {
    banks: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBankQueryProperties.value,
        {
          ...defaultProperties,
          transactionId: null,
          checkedIds: [],
        },
        "/banks",
      ),
    sales: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultSalesQueryProperties.value,
        { ...defaultProperties, itemId: null, checkedIds: [] },
        "/sales",
      ),
    recovery: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultRecoveryQueryProperties.value,
        { ...defaultProperties, itemId: null },
        "/sales/recovery",
      ),
    products: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultProductsQueryProperties.value,
        { ...defaultProperties, productId: null },
        "/sales/products",
      ),
    customers: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultThirdPartiesQueryProperties.value,
        { ...defaultProperties, thirdPartyId: null },
        "/sales/customers",
      ),
    providers: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultThirdPartiesQueryProperties.value,
        { ...defaultProperties, thirdPartyId: null },
        "/purchases/providers",
      ),
    ztickets: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultZTicketsQueryProperties.value,
        { ...defaultProperties, zTicketId: null },
        "/sales/ztickets",
      ),
    library: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultLibraryQueryProperties.value,
        { ...defaultProperties, documentId: null },
        "/library",
      ),
    auxiliaryLedger: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultAuxiliaryLedgerQueryProperties.value,
        defaultProperties,
        route.name === "purchases-auxiliaryLedger"
          ? "/purchases/auxiliaryLedger"
          : "/sales/auxiliaryLedger",
      ),
    generalLedger: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultGeneralLedgerQueryProperties.value,
        { ...defaultProperties },
        "/balancesheet/generalLedger",
      ),
    balance: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBalanceQueryProperties.value,
        { ...defaultProperties },
        "/balancesheet/balance",
      ),
    vat: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultVatQueryProperties.value,
        { ...defaultProperties, itemId: null },
        "/vat",
      ),
    adminVat: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultVatQueryProperties.value,
        { ...defaultProperties, itemId: null },
        `/admin/societies/${societyStore.society?.id}/declaration/vat`,
      ),
    das2: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultVatQueryProperties.value,
        { ...defaultProperties, itemId: null },
        "/vat/das2",
      ),
    adminDas2: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultVatQueryProperties.value,
        { ...defaultProperties, itemId: null },
        `/admin/societies/${societyStore.society?.id}/declaration/das2`,
      ),
    companyTaxAdvances: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultCompanyTaxAdvanceQueryProperties.value,
        { ...defaultProperties, itemId: null },
        `/admin/societies/${idSociety}/declaration/companyTaxAdvances`,
      ),
    payments: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPaymentQueryProperties.value,
        { ...defaultProperties, itemId: null },
        "/sales/payments",
      ),
    purchases: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPurchasesQueryProperties.value,
        { ...defaultProperties, itemId: null, checkedIds: [] },
        "/purchases",
      ),
    personal: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBankQueryProperties.value,
        { ...defaultProperties, transactionId: null },
        "/purchases/expense-notes",
      ),
    preCompta: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPreComptaQueryProperties.value,
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/config/precompta`,
      ),
    pendingPoints: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPendingPointsQueryProperties.value,
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/accounting/pendingpoints`,
      ),
    revisionGuide: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultRevisionGuideQueryProperties.value,
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/accounting/revisionguide`,
      ),
    balanceSheet: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBalanceSheetQueryProperties.value,
        { ...defaultProperties, itemId: null },
        `/admin/societies/${idSociety}/declaration/balancesheet`,
      ),
    adminRevision: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultRevisionQueryProperties.value,
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/accounting/revisionaccount`,
      ),
    adminRevisionCustomer: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultRevisionTPQueryProperties,
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/accounting/customers`,
      ),
    adminRevisionProvider: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultRevisionTPQueryProperties,
        { ...defaultProperties },
        `/admin/societies/${societyStore.society?.id}/accounting/providers`,
      ),
  };

  const linkBuilder = linkBuilders[props.state];

  if (linkBuilder) {
    link = linkBuilder();
  }

  return navigateTo(link);
}

function changeDateRange(event: { dateStart: string | null; dateEnd: string | null }) {
  buildLinkFromQueryProperties(
    event.dateStart ? formatDate(event.dateStart, "YYYY-MM-DD") : null,
    event.dateEnd ? formatDate(event.dateEnd, "YYYY-MM-DD") : null,
  );
}

function resetDateStartAndDateEnd() {
  const defaultStartDate = exerciceStore.defaultExercice.startDate;
  const defaultEndDate = exerciceStore.defaultExercice.endDate;
  buildLinkFromQueryProperties(
    defaultStartDate
      ? formatDate(defaultStartDate, "YYYY-MM-DD")
      : dayjs().startOf("year").format("YYYY-MM-DD"),
    defaultEndDate
      ? formatDate(defaultEndDate, "YYYY-MM-DD")
      : dayjs().endOf("year").format("YYYY-MM-DD"),
  );
}

async function openModalManageFiscalPeriod() {
  const { open, close } = useModal({
    component: CreateExerciceModal,
    attrs: {
      onClose(data) {
        if (isDefined(data.startDate) && isDefined(data.endDate)) {
          changeDateRange({
            dateStart: formatDate(data.startDate, "YYYY-MM-DD"),
            dateEnd: formatDate(data.endDate, "YYYY-MM-DD"),
          });
        }
        close();
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}
</script>

<style lang="scss" scoped>
.date-range-container {
  .fiscal-period-select-wrapper {
    display: flex;
    gap: $uds-spacing-1;
    align-items: center;
    justify-content: center;
    margin: $uds-spacing-1 $uds-spacing-1 0 $uds-spacing-1;
  }
}
</style>
