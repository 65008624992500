<template>
  <div v-show="isAnnotateActive" class="to-annotate-chip-container">
    <div class="chip-text">À annoter</div>
    <div class="chip-close" @click="buildLinkFromQueryProperties()">
      <uds-icon class="icon" icon-name="cancel" size="small" />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  state: string;
}>();

const storeComposable = useStoreComposable();
const exerciceStore = useExerciceStore();
const activeStore = computed<any>(() => storeComposable.activeStore(props.state));

const statePathProperties = computed(() => activeStore.value?.queryProperties);

const isAnnotateActive = computed<boolean>(() => {
  return statePathProperties.value?.toAnnotate ?? false;
});

interface LinkBuilders {
  [key: string]: () => string;
}

function buildLinkFromQueryProperties() {
  let link = "";
  const defaultStartDate = exerciceStore.defaultExercice.startDate;
  const defaultEndDate = exerciceStore.defaultExercice.endDate;
  const defaultProperties = {
    ...statePathProperties.value,
    toAnnotate: false,
    page: 1,
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  };

  const linkBuilders: LinkBuilders = {
    banks: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBankQueryProperties.value,
        {
          ...defaultProperties,
          transactionId: null,
          checkedIds: [],
        },
        "/banks",
      ),
    sales: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultSalesQueryProperties.value,
        { ...defaultProperties, itemId: null, checkedIds: [] },
        "/sales",
      ),
    purchases: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultPurchasesQueryProperties.value,
        { ...defaultProperties, itemId: null, checkedIds: [] },
        "/purchases",
      ),
    personal: () =>
      useBuildRouteFromQueryPropertiesComposable(
        defaultBankQueryProperties.value,
        { ...defaultProperties, transactionId: null },
        "/purchases/expense-notes",
      ),
  };

  const linkBuilder = linkBuilders[props.state];

  if (linkBuilder) {
    link = linkBuilder();
  }

  return navigateTo(link);
}
</script>

<style lang="scss" scoped>
.to-annotate-chip-container {
  border-radius: 17.5px;
  display: flex;
  align-items: center;
  height: 35px;
  background-color: $uds-google-blue;

  .icon {
    color: $uds-white;
    cursor: pointer;
  }

  .chip-text {
    font-weight: $uds-weight-500;
    font-size: 16px;
    line-height: 20px;
    margin-left: 10px;
    margin-right: 5px;
    color: $uds-white;
    white-space: nowrap;
  }

  .chip-close {
    display: flex;
    align-items: center;
    margin-right: 6px;
  }
}
</style>
