<template>
  <uds-simple-dropdown>
    <template #inputContent>
      <div
        class="advanced-filters-chip"
        @click="displayFilterAndFlagDropdown = !displayFilterAndFlagDropdown"
      >
        <div class="afc-chip-swap">
          <uds-icon class="afc-icon" icon-name="filter_list" size="small" />
        </div>
        <div v-if="workableWidth > 932 && !isMinified" class="afc-chip-text">Recherche avancée</div>
      </div>
    </template>
    <template #dropdownContent="{ hide }">
      <component :is="pathDropdown" @close="hide" />
    </template>
  </uds-simple-dropdown>
</template>

<script setup lang="ts">
const props = defineProps<{
  workableWidth: number;
  state: string;
}>();

const storeComposable = useStoreComposable();
const activeStore = computed<any>(() => storeComposable.activeStore(props.state));

const displayFilterAndFlagDropdown = ref(false);

const pathDropdown = computed(() => {
  const path = config.value.dropdownName;

  return defineAsyncComponent(() => import(`../../dropdowns/filters/${path}.vue`));
});

const isMinified = computed(() => {
  if (["adminRevision", "adminRevisionCustomer", "adminRevisionProvider"].includes(props.state)) {
    return activeStore.value.queryProperties.checkedIds.length > 0;
  }
  return false;
});

const config = computed(() => {
  switch (props.state) {
    case "adminSocieties":
      return {
        dropdownName: "SocietiesAdvancedFiltersDropdown",
      };
    case "genericAccount":
      return {
        dropdownName: "GenericAccountFiltersAndFlagsDropdown",
      };
    case "adminRevision":
    case "adminRevisionCustomer":
    case "adminRevisionProvider":
      return {
        dropdownName: "RevisionAdvancedFiltersDropdown",
      };
    default:
      return {
        dropdownName: null,
      };
  }
});
</script>

<style lang="scss">
.advanced-filters-chip {
  background: $uds-neutral-200;
  border-radius: $uds-radius-5;
  display: flex;
  align-items: center;
  height: 35px;
  min-width: 35px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  .afc-icon {
    cursor: pointer;
    color: $uds-neutral-800;
  }

  .afc-chip-swap {
    display: flex;
    align-items: center;
    margin: 0 $uds-spacing-1_5;
  }

  .afc-chip-text {
    margin-right: $uds-spacing-2;
    white-space: nowrap;
    color: $uds-neutral-800;
  }
}

.advanced-filters-dropdown {
  background: $uds-white;
  display: flex;
  flex-direction: column;
  gap: $uds-spacing-2;
  padding: $uds-spacing-2;

  .afd-filter-item {
    display: flex;
    align-items: center;
    color: $uds-neutral-800;
    cursor: pointer;

    & > div {
      width: 100%;
    }

    .uds-input-label {
      color: $uds-neutral-800;
    }

    .afd-filter-checkbox {
      display: flex;
      align-items: center;
    }
  }

  .afd-filter-line {
    display: flex;
    flex-direction: column;

    .afd-filter-line-label {
      color: $uds-neutral-800;
      font-size: 14px;
    }

    .afd-filter-line-items {
      display: flex;
      align-items: center;
      gap: $uds-spacing-1;

      .afd-tag {
        border-radius: $uds-spacing-2;
        padding: $uds-spacing-1 $uds-spacing-2;
        background-color: $uds-neutral-100;
        color: $uds-neutral-800;
        cursor: pointer;

        &.--active {
          background-color: $uds-primary-50;
          color: $uds-primary-500;
        }
      }
    }
  }
  .afd-filter-actions {
    display: flex;
    justify-content: flex-end;
    gap: $uds-spacing-1;
  }
}
</style>
